/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCampaigns = /* GraphQL */ `
  query GetCampaigns($account: String, $customer: String) {
    getCampaigns(account: $account, customer: $customer) {
      statusCode
      body
      __typename
    }
  }
`;
export const getConversations = /* GraphQL */ `
  query GetConversations($account: String, $campaign: String) {
    getConversations(account: $account, campaign: $campaign) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCustomers = /* GraphQL */ `
  query GetCustomers($account: String) {
    getCustomers(account: $account) {
      statusCode
      body
      __typename
    }
  }
`;
