import * as React from 'react';
import {useEffect, useState} from 'react';
import {API} from "aws-amplify";
import {getConversations} from "../../graphql/queries";
import _ from "lodash";
import BasicList from "../common/BasicList/BasicList";
import Loading from "../common/Loading/Loading";
import Box from '@mui/material/Box';

const convoStyles = {
    maxHeight: '100vh',
    overflowY: 'scroll',
    overflowX: 'hidden'
};

const ConversationList = ({campaign, account, onError, onSelect, title}) => {
    const [conversations, setConversations] = useState([]);
    const [messages, setMessages] = useState([]);
    const [loading, setLoading] = useState(true);

    const initConversationsQuery = async (campaign, account) => {

        console.log('Fetch conversations')
        setLoading(true)
        try {

            let conv_ids = []
            let messages = []
            if (!campaign) {
                setConversations([])
                setMessages([])
            } else {

                const result = await API.graphql({
                    query: getConversations,
                    variables: {"campaign": campaign, "account": account},
                    authMode: 'AMAZON_COGNITO_USER_POOLS'
                })

                let body = JSON.parse(result.data.getConversations.body)
                console.log(`Get Conversations : ${result.data.getConversations.statusCode} - ${body.message}`)
                if (!_.isUndefined(body.data) && !_.isEmpty(body.data)) {
                    conv_ids = body.data.ids
                    messages = body.data.messages
                }
                setConversations(conv_ids)
                setMessages(messages)
            }
            setLoading(false)
        } catch (error) {
            console.log('querry failed ->', error)
            setLoading(false)
            onError()
        }
    }

    useEffect(() => {
        // if (!campaign) return

        console.log('Fetch campaigns queries')
        initConversationsQuery(campaign ? campaign : '', account);

    }, [campaign])

    useEffect(() => {
        if (!messages || _.isEmpty(messages)) return

        // Filter messages by contact
        let filtered_msgs = _.filter(messages, msg=>{
            return conversations.includes(msg.receiver)
        })

        // group messagess by contact
        let grouped_msgs = _.groupBy(filtered_msgs, 'receiver')

        // Filter messages per conversation
        let latest_by_contact = []
        _.forEach(grouped_msgs, function (objs, contact) {
            let last_item = _.last(objs, 'insert_date')
            latest_by_contact.push({"primary": last_item.receiver, "secondary": last_item.insert_date})
        })

        // Order list
        let ordered_items = _.orderBy(latest_by_contact, ['secondary'], ['desc'])
        setConversations(ordered_items)


    }, [messages])

    const onSelectConversation = (e, conversation) => {
        e.preventDefault()
        console.log(conversation)

        // Filter Messages
        let filtered_messages = _.filter(messages, obj =>
            obj.sender.toLowerCase() === conversation.primary.toLowerCase() ||
            obj.receiver.toLowerCase() === conversation.primary.toLowerCase()
        )

        let ordered_items = _.orderBy(filtered_messages, ['insert_date'], ['asc']);


        onSelect(conversation, ordered_items)
    }

    return (
        <Box sx={convoStyles}>
            {
                loading ?
                    <Loading>
                        <BasicList items={conversations} title={title}/>
                    </Loading>
                    : <BasicList items={conversations} onSelect={onSelectConversation} title={title}/>
            }
        </Box>
    )
}

export default ConversationList