import React from "react";
import PropTypes from "prop-types";

import {Avatar, Grid, Typography, Badge} from "@mui/material";
import cx from "clsx";
import {ChatMsgStyle} from "./defaultChatMsg.styles";
import {ThemeProvider} from "@mui/material/styles";

const ChatMsg = (props) => {
    const {
        avatar,
        messages,
        side,
        GridContainerProps,
        GridItemProps,
        AvatarProps,
        getTypographyProps
    } = props;

    const classes = ChatMsgStyle
    const attachClass = (index) => {
        if (index === 0) {
            return classes[`${side}First`];
        }
        if (index === messages.length - 1) {
            return classes[`${side}Last`];
        }
        return "";
    };
    return (
        <ThemeProvider theme={ChatMsgStyle}>
            <Grid
                container
                spacing={1}
                justify={side === "right" ? "flex-end" : "flex-start"}
                {...GridContainerProps}
            >
                {side === "left" && (
                    <Grid item xs={1} {...GridItemProps}>
                        <Avatar
                            sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}
                            src={avatar}
                            {...AvatarProps}
                            className={cx(classes.avatar, AvatarProps.className)}
                        />
                    </Grid>
                )}
                <Grid item xs={11}>
                    {messages.map((msg, i) => {
                        const TypographyProps = getTypographyProps(msg, i, props);
                        let combined_styles = [classes.msg,
                            classes[side],
                            attachClass(i),
                            TypographyProps.className]
                        return (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={msg.id || i} className={classes[`${side}Row`]}>
                                <Badge color="secondary" badgeContent={msg.channel}>
                                    <Typography
                                        align={side}
                                        {...TypographyProps}
                                        sx={combined_styles}
                                    >
                                        {msg.message}
                                    </Typography>
                                </Badge>
                            </div>
                        );
                    })}
                </Grid>
                {side === " right" && (
                    <Grid item xs={1} {...GridItemProps}>
                        <Avatar
                            sx={{display: {xs: 'none', sm: 'none', md: 'flex'}}}
                            src={avatar}
                            {...AvatarProps}
                            className={cx(classes.avatar, AvatarProps.className)}
                        />
                    </Grid>
                )}
            </Grid>
        </ThemeProvider>
    )
}

ChatMsg.propTypes = {
    avatar: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.object),
    side: PropTypes.oneOf([" left", " right"]),
    GridContainerProps: PropTypes.shape({}),
    GridItemProps: PropTypes.shape({}),
    AvatarProps: PropTypes.shape({}),
    getTypographyProps: PropTypes.func
};
ChatMsg.defaultProps = {
    avatar: "",
    messages: [],
    side: " left",
    GridContainerProps: {},
    GridItemProps: {},
    AvatarProps: {},
    getTypographyProps: () => ({})
};

export default ChatMsg;
