import React, {useState} from 'react'
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {mainNavbarItems} from "./consts/navBarItems";
import {useLocation, useNavigate} from "react-router-dom";
import Cai3p0Icon from "../cai3p0/cai3p0logo";
import _ from "lodash";


const drawerWidth = 240;

function Navbar(props) {
    const {window} = props;
    const [mobileOpen, setMobileOpen] = useState(false);
    const navigate = useNavigate()
    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navigation_component = () => {

        if (_.isUndefined(mainNavbarItems)) return

        let selected = _.find(mainNavbarItems, {"route": location.pathname.split('/')[1]})
        if (!_.isUndefined(selected)) {
            return selected.label;
        }
    };

    const drawer = (
        <div>
            <Toolbar>
                <Cai3p0Icon
                    sx={{display: {xs: 'none', sm: 'flex', md: 'flex'}, mr: 1, fontSize: '2em'}}
                    fill={'#000'}
                />
                <Typography
                    variant="h6" noWrap component="div">
                    CAI3P0
                </Typography>
            </Toolbar>
            <Divider/>
            <List>
                {mainNavbarItems.map((item, index) => (
                    <ListItem key={item.id} onClick={() => navigate(item.route)} disablePadding>
                        <ListItemButton>
                            <ListItemText primary={item.label}/>
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{display: 'flex'}}>
            <CssBaseline/>
            <AppBar
                position="fixed"
                sx={{
                    width: {sm: `calc(100% - ${drawerWidth}px)`},
                    ml: {sm: `${drawerWidth}px`},
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography
                        variant="h6" noWrap component="div">
                        {navigation_component()}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box
                component="nav"
                sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: false, // Better open performance on mobile.
                    }}
                    sx={{
                        display: {xs: 'block', sm: 'none'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: 'none', sm: 'block'},
                        '& .MuiDrawer-paper': {boxSizing: 'border-box', width: drawerWidth},
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>

        </Box>
    );
}

Navbar.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default Navbar;