import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Unstable_Grid2";
import ConversationList from "../ConversationList/ConversationList";
import ChatMsg from "./ChatMsg";
import _ from "lodash";

const ConversationMessages = ({onError, campaign, account}) => {
    const [messages, setMessages] = useState([])
    const [conversation, setConversation] = useState('')

    const onSelectConversation = (conversation, messages) => {
        console.log(conversation)
        setMessages(messages)
        setConversation(conversation)
    }

    useEffect(() => {
        if (!account) {
            setMessages([])
        }
    }, [account])

    useEffect(() => {
        if (!campaign) {
            setMessages([])
        }
    }, [campaign])

    return (
        <Box sx={{minWidth: 275}}>
            <Grid container spacing={2}>
                {(!_.isEmpty(campaign)) ? (
                    <Grid xs={4} sm={4} md={4} lg={3} sx={{display: campaign ? 'flex' : 'none'}}>
                        <ConversationList campaign={campaign} account={account} onError={onError} onSelect={onSelectConversation}/>
                    </Grid>
                ) : ('')}
                {(!_.isEmpty(messages)) ? (
                    <Grid xs={8} sm={8} md={8} lg={9}>
                        <Box
                            sx={{
                                display: conversation ? 'flex' : 'none',
                                m: 1,
                                bgcolor: 'primary.light',
                                p: 2,
                                color: 'white',
                                border: '1px solid',
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                borderRadius: 1,
                                fontSize: '0.875rem',
                                fontWeight: '700',
                            }}
                        >
                            {conversation.primary}
                        </Box>
                        {messages.map((item, index) => (
                            <ChatMsg
                                avatar={""}
                                side={item.role === 'CONTACT' ? 'left' : 'right'}
                                messages={[item]}
                            />
                        ))}
                    </Grid>
                ) : ('')}
            </Grid>
        </Box>
    )
}

export default ConversationMessages