import {createSvgIcon} from "@mui/material";
import * as React from "react";

const Cai3p0Icon = createSvgIcon(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 500.000000 500.000000"
         preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
            stroke="none">
            <path d="M2456 4489 c-22 -5 -54 -18 -71 -29 -33 -20 -85 -87 -85 -108 0 -9
-109 -12 -459 -12 l-459 0 -16 25 c-62 94 -216 83 -266 -20 -34 -71 -20 -138
39 -188 64 -53 150 -48 212 14 l39 39 454 0 454 0 11 -29 c42 -99 182 -149
287 -102 42 19 114 94 114 119 0 29 225 -8 415 -68 276 -88 481 -242 575 -432
56 -114 69 -196 70 -443 0 -232 14 -473 31 -542 18 -76 63 -151 151 -253 87
-101 138 -168 138 -180 0 -5 -21 -22 -47 -38 -94 -60 -163 -146 -163 -202 0
-12 10 -40 22 -61 l22 -40 -47 -43 c-56 -51 -62 -91 -22 -150 14 -20 25 -39
25 -41 0 -3 -12 -5 -28 -5 -72 0 -102 -67 -78 -173 21 -89 20 -180 0 -223 -26
-54 -62 -68 -163 -60 -229 17 -356 18 -431 2 -151 -32 -271 -116 -344 -240
-34 -57 -42 -66 -68 -66 -37 0 -95 -30 -130 -67 -110 -115 -55 -314 99 -359
132 -39 261 48 279 188 7 53 -15 119 -57 170 -34 43 -31 53 24 118 108 125
259 164 536 137 224 -22 303 4 363 121 43 84 47 182 12 309 -4 16 0 23 18 28
30 9 66 42 84 77 20 38 17 81 -7 112 l-21 26 31 35 c16 19 35 54 42 77 10 37
8 49 -10 92 l-21 50 23 24 c12 13 54 44 93 68 137 85 127 153 -54 363 -164
190 -160 178 -173 641 -12 436 -24 504 -120 667 -43 73 -175 207 -258 263 -85
57 -244 132 -360 170 -96 31 -290 73 -393 85 -37 4 -58 11 -58 19 0 7 -13 31
-29 53 -47 65 -145 101 -225 82z"/>
            <path d="M2034 4086 c-17 -8 -44 -30 -60 -50 l-29 -36 -563 0 -563 0 -24 -25
c-30 -29 -31 -45 -4 -79 l20 -26 569 0 568 0 18 -27 c49 -77 161 -99 233 -45
93 68 96 199 6 267 -44 34 -122 43 -171 21z"/>
            <path d="M3206 3785 c-47 -17 -103 -69 -122 -112 l-14 -33 -449 0 -449 0 -21
35 c-23 39 -87 74 -134 75 -33 0 -94 -24 -120 -48 -49 -44 -65 -139 -33 -200
57 -110 226 -119 291 -15 l20 32 446 1 447 0 12 -29 c16 -41 68 -91 115 -112
141 -65 305 44 305 203 0 83 -45 154 -122 192 -58 29 -112 33 -172 11z"/>
            <path d="M1600 3692 c-93 -46 -88 -189 7 -229 50 -20 99 -9 140 32 28 28 33
40 33 83 0 58 -25 97 -76 118 -44 18 -62 18 -104 -4z"/>
            <path d="M2341 3290 c-50 -12 -113 -61 -139 -108 l-23 -42 -660 0 c-646 0
-659 0 -679 -20 -23 -23 -26 -58 -6 -86 14 -18 36 -19 679 -24 l665 -5 34 -49
c120 -178 398 -95 398 119 0 96 -64 184 -154 210 -54 16 -65 17 -115 5z"/>
            <path d="M2832 3213 c-75 -37 -106 -146 -60 -212 37 -54 72 -75 126 -75 91 0
152 61 152 151 0 115 -115 187 -218 136z"/>
            <path d="M2112 2766 c-96 -31 -153 -109 -153 -209 0 -62 17 -110 53 -149 104
-109 301 -81 359 51 l14 31 427 0 c353 0 428 -2 428 -13 0 -29 59 -94 104
-115 114 -54 242 -9 300 105 20 39 21 132 2 177 -22 53 -80 103 -138 121 -99
29 -215 -18 -254 -104 l-19 -40 -427 -1 c-353 0 -428 2 -428 13 0 8 -13 32
-29 54 -50 70 -157 105 -239 79z"/>
            <path d="M1574 2696 c-42 -19 -82 -73 -89 -121 -14 -93 82 -186 175 -170 71
12 130 81 130 152 0 14 -9 46 -21 69 -34 70 -122 102 -195 70z"/>
            <path d="M2965 2229 c-43 -12 -102 -64 -125 -110 l-20 -39 -717 0 -716 0 -35
41 c-59 69 -129 86 -208 50 -71 -32 -110 -127 -85 -204 16 -46 80 -104 126
-113 59 -11 120 13 164 62 l39 44 721 0 c599 0 721 -2 721 -13 0 -26 60 -97
99 -116 141 -73 297 4 325 161 15 84 -45 191 -128 229 -43 20 -111 23 -161 8z"/>
            <path d="M3325 1768 c-50 -17 -95 -57 -118 -104 l-21 -44 -421 0 -421 0 -34
52 c-49 73 -103 101 -191 96 -87 -5 -136 -36 -173 -109 -94 -189 95 -377 283
-283 41 21 58 37 79 75 l27 49 426 0 427 0 12 -29 c14 -36 64 -87 104 -109 41
-22 151 -22 191 0 72 39 114 101 122 184 10 95 -54 192 -146 223 -41 13 -105
13 -146 -1z"/>
            <path d="M1634 1686 c-70 -31 -99 -109 -65 -179 25 -52 65 -77 121 -77 79 0
134 56 132 136 -1 98 -98 159 -188 120z"/>
        </g>
    </svg>,
    'Plus',
);

export default Cai3p0Icon;